<template>
  <div class="main-conent main-conent-minheight form-footer">

    <!-- <page-header into="">
      <strong></strong>
    </page-header> -->
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px">
      <el-tabs v-model="activeName">
        <el-tab-pane label="基础信息" name="first">
          <el-card shadow="never" class="border-none margin-t24">
            <div slot="header">
              <span></span>
            </div>
            <el-row :gutter="24">
              <el-col>
                <el-form-item label="分类" prop="category">
                  <el-select v-model="ruleForm.categoryId" clearable placeholder="请选择分类">
                    <el-option v-for="item in categoties" :key="item.id" :label="item.title" :value="item.id"
                      :disabled="item.disabled">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="标题" prop="title">
                  <el-input v-model="ruleForm.title"></el-input>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="作者" prop="author">
                  <el-input v-model="ruleForm.author"></el-input>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="浏览获得功力值" prop="point">
                  <el-input-number v-model="ruleForm.point"></el-input-number>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="获得功力值浏览时间（秒）" prop="readTime">
                  <el-input-number v-model="ruleForm.readTime"></el-input-number>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="转发获得功力值" prop="point">
                  <el-input-number v-model="ruleForm.sharePoint"></el-input-number>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="是否上架" prop="isPublish">
                  <el-switch style="display: block" v-model="ruleForm.isPublish" active-color="#13ce66"
                    inactive-color="#ff4949" active-text="已上架" inactive-text="未上架">
                  </el-switch>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="作者头像图" prop="avatar">
                  <el-upload class="avatar-uploader" :action="updateUrl" :show-file-list="false"
                    :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :headers="uploadHeader">
                    <img v-if="ruleForm.avatar" :src="ruleForm.avatar" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="封面图" prop="url">
                  <el-upload class="avatar-uploader" :action="updateUrl" :show-file-list="false"
                    :on-success="handleUrlSuccess" :before-upload="beforeAvatarUpload" :headers="uploadHeader">
                    <img v-if="ruleForm.url" :src="ruleForm.url" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="转发分享图" prop="shareUrl">
                  <el-upload class="avatar-uploader" :action="updateUrl" :show-file-list="false"
                    :on-success="handleShareUrlSuccess" :before-upload="beforeAvatarUpload" :headers="uploadHeader">
                    <img v-if="ruleForm.shareUrl" :src="ruleForm.shareUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="排序" prop="sort">
                  <el-input-number v-model="ruleForm.sort"></el-input-number>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="配置管理" name="second">
          <el-card shadow="never" class="border-none margin-t24">
            <div slot="header">
              <span></span>
            </div>
            <el-row :gutter="24">
              <el-col>
                <el-form-item label="外链" prop="extraLink">
                  <el-input v-model="ruleForm.extraLink"></el-input>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="摘要" prop="zhaiyao">
                  <el-input v-model="ruleForm.zhaiyao"></el-input>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="正文" prop="content">

                </el-form-item>

              </el-col>
              <el-col>
                <div style="display:flex;flex-flow: row nowrap;justify-content: space-around;">
                  <div id="div1" style="width: 375px;">

                  </div>
                </div>
              </el-col>
            </el-row>
          </el-card>
        </el-tab-pane>
      </el-tabs>

    </el-form>

    <form-footer-toolbar class="text-right">
      <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
    </form-footer-toolbar>

  </div>

</template>
<script>
import store from '@/store';
// import PageHeader from '@/layout/components/PageHeader';
import FormFooterToolbar from '@/layout/components/FormFooterToolbar';
import { getModel, saveModel } from '@/service/article_product.js';
import E from "wangeditor";
import { getComboBox } from '@/service/article_category.js';

export default {
  components: {
    FormFooterToolbar
  },
  data() {
    return {
      editor: Object,
      activeName: 'first',
      //lists image media table textcolor wordcount contextmenu preview
      updateUrl: process.env.VUE_APP_APIHOST + 'file/uploadserverimg',
      ruleForm: {
        id: 0,
        title: '',
        zhaiyao: '',
        author: '',
        avatar: '',
        isPublish: false,
        content: '',
        isDraft: true,
        readTime: 10,
        point: 0,
        sharePoint: 0,
        url: '',
        shareUrl: '',
        extraLink: '',
        sort: 99,
        categoryId: ''
      },
      rules: {
      },
      uploadHeader: {
        "Authorization": "Bearer " + store.getters.token
      },
      categoties: []
    };
  },
  mounted() {
    this.categoryDataBinding();
    this.wangEditorInit().then(val => {
      if (this.$route.query.articleId) {
        this.ruleForm.id = this.$route.query.articleId;
        this.dataBinding();
      }
    });
  },
  created() {

  },
  methods: {
    dataBinding() {
      getModel(this.ruleForm.id).then(res => {
        this.ruleForm = res;
        this.editor.txt.html(res.content);
      });
    },
    //分类加载
    categoryDataBinding() {
      getComboBox({ type: 2 }).then(cob => {
        this.categoties = cob;
      });
    },
    //编辑器初始化
    wangEditorInit() {
      var __this = this;
      // const editor = new E("#div1");
      __this.editor = new E("#div1");
      // 配置 server 接口地址
      __this.editor.config.uploadImgServer = process.env.VUE_APP_APIHOST + 'file/uploadimg';
      // 限制大小,默认限制图片大小是 5M 
      __this.editor.config.uploadImgMaxSize = 2 * 1024 * 1024;
      //限制类型
      __this.editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
      //自定义参数
      __this.editor.config.uploadImgParams = {
        "Authorization": "Bearer " + store.getters.token
      };
      // 自定义 header
      __this.editor.config.uploadImgHeaders = {
        "Authorization": "Bearer " + store.getters.token
      };
      // 自定义 timeout 时间 
      __this.editor.config.uploadImgTimeout = 60 * 1000;
      //自定义动作配置
      __this.editor.config.uploadImgHooks = {
        // 上传图片之前
        before: function (xhr) {
          //console.log(xhr);
          // 可阻止图片上传
          // return {
          //   prevent: true,
          //   msg: '需要提示给用户的错误信息'
          // };
        },
        // 图片上传并返回了结果，图片插入已成功
        success: function (xhr) {
          console.log('success', xhr);
        },
        // 图片上传并返回了结果，但图片插入时出错了
        fail: function (xhr, editor, resData) {
          console.log('fail', resData);
        },
        // 上传图片出错，一般为 http 请求的错误
        error: function (xhr, editor, resData) {
          console.log('error', xhr, resData);
        },
        // 上传图片超时
        timeout: function (xhr) {
          console.log('timeout');
        },
        // 图片上传并返回了结果，想要自己把图片插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
        customInsert: function (insertImgFn, result) {
          // result 即服务端返回的接口
          console.log('customInsert', result);
          if (result.code != 0) {
            this.$message("保存出错：" + result.message);
          } else {
            // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
            result.data.forEach(element => {
              insertImgFn(element);
            });
          }

        }
      };
      //同步数据
      __this.editor.config.onchange = function (newHtml) {
        __this.ruleForm.content = newHtml;
      };
      // 配置触发 onchange 的时间频率，默认为 200ms  // 修改为 500ms
      __this.editor.config.onchangeTimeout = 500;
      //启动编辑器
      __this.editor.create();
      return Promise.resolve();
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.isDraft = false;
          saveModel(this.ruleForm).then(res => {
            if (res === true) {
              this.$router.go(-1);
            }
            else {
              this.$message("保存异常");
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleUrlSuccess(res) {
      if (res.data && res.data.length > 0) {
        this.ruleForm.url = res.data[0];
      }
    },
    handleAvatarSuccess(res) {
      console.log(res.data);
      if (res.data && res.data.length > 0) {
        this.ruleForm.avatar = res.data[0];
      }
    },
    handleShareUrlSuccess(res) {
      if (res.data && res.data.length > 0) {
        this.ruleForm.shareUrl = res.data[0];
      }
    },
    beforeAvatarUpload(file) {
      const isIMG = (file.type === 'image/jpeg' || file.type === 'image/png');
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isIMG) {
        this.$message.error('上传头像图片只能是 JPG或者PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 3MB!');
      }
      return isIMG && isLt2M;
    }
  }
};
</script>
<style lang="scss">
.ql-editor {
  min-height: 300px;
}
</style>